import React from "react";
import "../App.css"
import logo from "../assets/logo1.png"

export default function Footer(){
    return(
        <footer>
        <div className="flex row justify-sides align" style={{borderBottom: "1px solid gray", padding: 10}}>
            <img src={logo} alt="logo" width="90px" />
            <a href="https://frogit.lv/privacy" target="_blank">Privātuma politika</a>
        </div>
        <div className="flex row justify-sides">
            <p id="demo"> © 2024 <a href="https://frogit.lv">Frogit.lv</a>. All rights reserved. </p>

        </div>
    </footer>
    )
}