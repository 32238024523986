import React from "react";
import "../App.css"

export default function Features(){
    window.scrollTo(0, 0)

    return(
        <section id="features">
        <h2>Dari to, kas Tev padodas vislabāk un uztici mums savu klientu pārvaldību</h2>
        <p>Digitalizē savu biznesu, esi sasneidzams. Visas šīs iespējas būt Tev pieejamas pirmajam. pieraksties
            jaunumiem un pirmais uzzini, kad sistēma tiks piedāvāta lietošanai.</p>
        <div className="featurelist">
            <div className="text-md"><i className="material-icons">people</i>
                <h6>Klientu pārvaldība</h6>
                <p className="text-sm text-muted">Pārskati, sazinies un veido savu datubāzi</p>
            </div>

            <div className="text-md"><i className="material-icons">layers</i>
                <h6>Personīgais profils</h6>
                <p className="text-sm text-muted">Ļauj klientiem Tevi atrast, redzēt un iepazīt</p>
            </div>
            <div className="text-md"><i className="material-icons">storage</i>
                <h6>Neierobežots datu apjoms</h6>
                <p className="text-sm text-muted">Uzglabā savus datus bez ierobežojuma</p>
            </div>
            <div className="text-md"><i className="material-icons">headphones</i>
                <h6>Bezmaksas atbalsts</h6>
                <p className="text-sm text-muted">Sazinies un saņem personisku atbildi </p>
            </div>
            <div className="text-md"><i className="material-icons">book</i>
                <h6>Plānotājs vienmēr kabatā</h6>
                <p className="text-sm text-muted">Digitālā pieraksta sistēma Tev vienmēr pieejama ierīcē ar interneta
                    pieslēgumu</p>
            </div>
            <div className="text-md"><i className="material-icons">how_to_reg</i>
                <h6>Radīts Tavām vajadzībām</h6>
                <p className="text-sm text-muted">Sasniedz vairāk, esi piekļūstamāks</p>
            </div>
        </div>
    </section>
    )
}